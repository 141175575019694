import React, {FC} from 'react';
import {navigate} from 'gatsby';
import classNames from 'classnames';

import Layout from '../../components/layout/layout';
import {PageHero} from '../../components/page-hero';
import Button from '../../components/button';
import Intergrations from '../../components/homePageIntegration';

import dash from '../../img/Dash-Screen-for-Website.png';
import onlinePublishers from '../../img/soulitions/online-publishers.png';
import searchRankings from '../../img/soulitions/search-rankings.png';
import webBrowsers from '../../img/soulitions/web-browsers.png';
import websiteSecurity from '../../img/soulitions/website-security.png';

const services = [
  {
    title: null,
    text: (
      <>
        <p>
          If you are an online publisher or blogger your business depends on great content, building your organic
          traffic, and creating a brand that will attract social shares, engagement, return visits and healthy
          advertising revenue.
        </p>
        <p>
          But, every day your website can face an increasing range of threats. Server problems, slow landing pages,
          broken links, frustrating mobile experiences, embarrassing spelling mistakes, changing SEO rules, 3rd party
          services breaking, or security issues that put your publishing business at risk.
        </p>
        <p>
          To make matters worse, these issues can linger unnoticed, causing visitors to bounce, affecting your search
          rankings and potentially affecting your relationship with top tier advertisers.
        </p>
        <p>
          Hexometer helps protect your business by continuously monitoring what others can’t, so you can catch problems
          before they affect your customers.
        </p>
        <p>
          Let's look at some of the biggest challenges facing online publishers today and how you can stay one step
          ahead.
        </p>
      </>
    ),
    image: onlinePublishers,
    alt: 'Online publishers',
  },
  {
    title: 'Web browsers dropping 3rd party cookies in 2021 & the impact on the publishing industry',
    text: (
      <>
        <p>
          The incoming transition towards blocking 3rd party cookies will likely change the advertising landscape,
          moving the focus away from automated retargeting and more towards traditional media buying.
        </p>
        <p>
          As a result, website metrics such as performance, user experience, website health and security will be much
          more important for advertisers when deciding ad placements and ensuring campaign ROI.
        </p>
        <p>
          Is your website ready for these changes? With Hexometer by your side you will get instant alerts if something
          on your website breaks, when spelling or grammar mistakes creep in or when slow or insecure pages are
          detected.
        </p>
      </>
    ),
    image: searchRankings,
    alt: 'Search rankings',
  },
  {
    title: 'Google Web Vitals and the future of search rankings for publishers, influencers and bloggers',
    text: (
      <>
        <p>
          Consistent website performance across mobile and desktop is going to become a key factor of success this year
          for publishers. In fact, Google’s focus on web vitals and in particular introducing this as a ranking signal
          means this will likely positively impact publishers that have fast loading pages, but could cause a drop in
          traffic for those that have slow loading pages or broken links / assets.
        </p>
        <p>
          Hexometer monitors your website 24/7 and alerts you when slow pages are detected helping you find the cause of
          the problem and fix the issues before they affect your search rankings.
        </p>
      </>
    ),
    image: webBrowsers,
    alt: 'Web browsers',
  },
  {
    title: 'Bulletproof your website security',
    text: (
      <>
        <p>
          With the rise in cybercrime, it is more important than ever to make sure your website is secure.
          Cybercriminals are always looking for an opportunity to exploit a vulnerability or a security breach that can
          lead to data loss, leaked data or your website being blacklisted by the search engines and advertising
          networks.
        </p>
        <p>
          Hexometer continually checks if your domain is blacklisted by the leading security authorities and keeps an
          eye on your security headers, SSL best practices, blacklisted IP’s as well as your homepage for malicious
          URLs.
        </p>
      </>
    ),
    image: websiteSecurity,
    alt: 'Website security',
  },
  {
    title: null,
    text: (
      <div className="flex-align-center">
        <h5>Get started in minutes, not days</h5>
        <p>
          Start monitoring your website in minutes to catch Availability, Performance, User experience, SEO, Health and
          Security problems, before they affect your customers business. No code changes or software installs required.
        </p>
        <Button primary className="trial_btn" onClick={() => navigate('/pricing/')}>
          Click here to get started in minutes
        </Button>
      </div>
    ),
    image: dash,
    alt: 'AI sidekick to protect and grow eCommerce business',
  },
];

const OnlinePublishers: FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero
        title="24/7 website monitoring designed 
        for online publishers"
        subtitle="Hexometer is your AI sidekick that works behind the scenes, monitoring your website and marketing 24/7 so you can focus on your publishing business."
      />
      <div className="ecommerce-and-retail">
        {services.map((item, index) => (
          <section
            key={item.image}
            className={classNames('services', {grey: index % 2 !== 0}, {'light-grey': index % 2 === 0})}
          >
            <div className="container">
              {item.title && (
                <h3 className="f_size_27 f_700 t_color3 l_height40 mt_20 mb_50 text-center">{item.title}</h3>
              )}
              <div
                className={classNames('row_services', {
                  'flow-reverse': index % 2 !== 0,
                })}
              >
                <div className="col-12 col-lg-6 p-0 ">{item.text}</div>
                <div className="col-12 col-lg-6 p-0 d-flex justify-content-center align-items-center text-center">
                  <img src={item.image} alt={item.alt} className="service-img" />
                </div>
              </div>
            </div>
          </section>
        ))}
        <br />
        <br />
        <br />
        <Intergrations />
      </div>
    </Layout>
  );
};

export default OnlinePublishers;
